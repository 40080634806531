.App {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
  .mainContainer {
    position: relative;
    min-height: 95vh;
    padding-left: 3%;
    padding-top: 2%;
  }
  
  .gridWrapper {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
  }
  
  .dataContainer {
    width: 776px;
    padding: 16px;
    font-family: "Menlo";
  }

  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .dataContainer {
      width: 100%;
    }
  
    .header {
      font-size: 18px;
    }
  
    .descriptionBox {
      font-size: 36px;
      line-height: 44px;
    }
  
    .descriptionBox2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .header {
      font-size: 16px;
    }
  
    .descriptionBox {
      font-size: 28px;
      line-height: 34px;
    }
  
    .descriptionBox2 {
      font-size: 14px;
      line-height: 18px;
    }
  }
  
  /* lime green lines */
  /* Vertical line */
  .verticalLine {
    position: fixed; /* Change to 'absolute' if you want the line to move with the content when scrolling */
    top: 0;
    bottom: 0;
    left: 90%; /* Center the line horizontally */
    margin-left: -1.5px; /* Adjust to half of the line's width (3px / 2) to keep it centered */
    width: 10px;
    background-color: #70FF00;
  }

  .dividerContainer {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .divider {
    height: 3px;
    background-color: #70FF00;
    border: none;
    width: 100%
  }
  
  .clickable-text {
    cursor: pointer;
    text-decoration: none;
    color: #000000;
  }
  
  .clickable-text:hover {
    border-bottom: 3px solid #70FF00;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header {
    font-family: 'Menlo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #454545;
    margin-bottom: 4em;
    cursor: pointer;
  }
  
  .descriptionBox {
    font-family: 'Menlo';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 4rem;
  }
  
  .descriptionBox2 {
    font-family: 'Menlo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 3rem;
  }

  p {
    font-family: 'Menlo';
    font-style: normal;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    margin: 0em;
  }

  p.title {
    font-weight: bold;
  }