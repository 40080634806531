.customerPageLink {
  font-family: 'Menlo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 3rem;
}
