.transferButton {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  font-family: Menlo;
  color: #0f0f0f;
  width: 15%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(127, 255, 127, 0.2);
}

.transferButton:disabled {
  border: 1px solid #ccc;
  background: #27272740;
}