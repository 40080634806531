.linkButton {
  background-color: white;
  color: black;
  text-decoration: none;
  border: none;
  font-family: "Menlo";
  font-size: 18px;
}

.linkButton:hover {
  background-color: #70FF00;
  color: #000000;
  cursor: pointer;
}