.retweet-url-container {
  display: flex;
  flex-direction: column;
  font-family: Menlo;
}

.retweet-lookup-master-container {
  font-family: Menlo;
}

.lookup-button {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  font-family: Menlo;
  color:black;
  width: 15%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(127, 255, 127, 0.2);
}

.lookup-button:disabled {
  border: 1px solid #ccc;
  background: #27272740;
}

.section-divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
