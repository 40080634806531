.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Menlo;
}


input {
  padding: 7px 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  color: #0f0f0f;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
  margin-top: 0.5em;
}

label {
  font-size: 18px;
  line-height: 18px;
  color: #0f0f0f;
  font-weight: bold;
  margin-right: 2%;
}
